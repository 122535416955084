import React, { useState } from "react";
import { Button, Input, NavigationWrapper, PhoneNumberInput } from "modules/common/components";
import { Controller, useForm } from "react-hook-form";
import { validators } from "modules/common/utils/functions";
import { useWindowSize, BREAKPOINTS } from "modules/common/hooks";
import API from "api";

const PropertyForm = () => {
	const { width } = useWindowSize();
	const [loading, setLoading] = useState(false);
	const {
		handleSubmit,
		control,
		formState: { errors },
		reset,
	} = useForm({
		defaultValues: {
			firstName: "",
			email: "",
			phoneNumber: "",
			streetAddress: "",
			city: "",
			state: "",
			zipCode: "",
			type: "",
			bathroomCount: "",
			bedroomCount: "",
			area: "",
			price: "",
			description: "",
			pictures: "",
		},
	});

	const onSubmit = async (data) => {
		setLoading(true);
		try {
			await API.sendPropertyApplication(data);
			reset();
			setLoading(false);
		} catch (e) {
			setLoading(false);
		}
	};

	return (
		<NavigationWrapper containerClassName=" " title={"List Your Property"}>
			<div className="bg-_blue lg:bg-white h-[120px] lg:hidden"></div>
			<div className="pt-[80.37px] lg:pt-[160px]">
				<div className="text-center mb-6 px-5">
					<h1 className="text-2xl lg:text-6xl font-bold mt-5">Promote Your Property with Ease</h1>
					<p className="text-base lg:text-lg mt-2">
						Provide detailed information about your property to connect with the right buyers. Fill out the
						form below and submit to get started.
					</p>
				</div>
				<div className="bg-white px-5 pt-[0px] md:pt-[0px] lg:flex lg:justify-between  lg:pr-0 1400:max-w-[1400px] 1400:mx-auto">
					<form
						onSubmit={handleSubmit(onSubmit)}
						className=" mx-auto bg-_blue rounded-[11.89px] lg:rounded-[20.22px] w-[100%] md:w-[80%] px-[17px] lg:px-[30px] pt-[36.25px] lg:pt-[60px] pb-[17.03px] lg:pb-[30.46px] mb-[197.08px]"
					>
						<div className="flex flex-col space-y-[13.08px] lg:space-y-[22.24px]">
							<Controller
								control={control}
								name="firstName"
								rules={{ validate: validators.validateString, required: true }}
								render={({ field: { onChange, value, ref } }) => (
									<Input
										placeholder={"First Name"}
										onChange={onChange}
										hasError={!!errors.firstName}
										value={value}
										ref_={ref}
									/>
								)}
							/>

							<Controller
								control={control}
								name="email"
								rules={{ validate: validators.validateEmail, required: true }}
								render={({ field: { onChange, value, ref } }) => (
									<Input
										placeholder={"Email"}
										type="email"
										onChange={onChange}
										hasError={!!errors.email}
										value={value}
										ref_={ref}
									/>
								)}
							/>
							<Controller
								control={control}
								name="phoneNumber"
								rules={{ validate: validators.validatePhoneNumber, required: true }}
								render={({ field: { onChange, value, ref } }) => (
									<PhoneNumberInput
										ref_={ref}
										value={value}
										onChange={onChange}
										hasError={!!errors.phoneNumber}
									/>
								)}
							/>
							<Controller
								control={control}
								name="streetAddress"
								rules={{ validate: validators.validateString, required: true }}
								render={({ field: { onChange, value, ref } }) => (
									<Input
										placeholder={"Street Address"}
										onChange={onChange}
										hasError={!!errors.streetAddress}
										value={value}
										ref_={ref}
									/>
								)}
							/>

							<Controller
								control={control}
								name="city"
								rules={{ validate: validators.validateString, required: true }}
								render={({ field: { onChange, value, ref } }) => (
									<Input
										placeholder={"City"}
										onChange={onChange}
										hasError={!!errors.city}
										value={value}
										ref_={ref}
									/>
								)}
							/>

							<Controller
								control={control}
								name="state"
								rules={{ validate: validators.validateString, required: true }}
								render={({ field: { onChange, value, ref } }) => (
									<Input
										placeholder={"State"}
										onChange={onChange}
										hasError={!!errors.state}
										value={value}
										ref_={ref}
									/>
								)}
							/>

							<Controller
								control={control}
								name="zipCode"
								rules={{ validate: validators.validateString, required: true }}
								render={({ field: { onChange, value, ref } }) => (
									<Input
										placeholder={"Zip Code"}
										type="number"
										onChange={onChange}
										hasError={!!errors.zipCode}
										value={value}
										ref_={ref}
									/>
								)}
							/>
							<Controller
								control={control}
								name="bathroomCount"
								rules={{ validate: validators.validateNumber, required: true }}
								render={({ field: { onChange, value, ref } }) => (
									<Input
										placeholder={"Bathroom Count"}
										isNumeric
										onChange={onChange}
										hasError={!!errors.bathroomCount}
										value={value}
										ref_={ref}
									/>
								)}
							/>

							<Controller
								control={control}
								name="bedroomCount"
								rules={{ validate: validators.validateNumber, required: true }}
								render={({ field: { onChange, value, ref } }) => (
									<Input
										placeholder={"Bedroom Count"}
										isNumeric
										onChange={onChange}
										hasError={!!errors.bedroomCount}
										value={value}
										ref_={ref}
									/>
								)}
							/>

							<Controller
								control={control}
								name="area"
								rules={{ validate: validators.validateNumber, required: true }}
								render={({ field: { onChange, value, ref } }) => (
									<Input
										placeholder={"Area (sq ft)"}
										isNumeric
										onChange={onChange}
										hasError={!!errors.area}
										value={value}
										ref_={ref}
									/>
								)}
							/>

							<Controller
								control={control}
								name="price"
								rules={{ validate: validators.validateNumber, required: true }}
								render={({ field: { onChange, value, ref } }) => (
									<Input
										placeholder={"Price"}
										isNumeric
										prefix="$"
										onChange={onChange}
										hasError={!!errors.price}
										value={value}
										ref_={ref}
									/>
								)}
							/>

							<Controller
								control={control}
								name="type"
								rules={{ validate: validators.validateString, required: true }}
								render={({ field: { onChange, value, ref } }) => (
									<Input
										placeholder={"Property Type"}
										onChange={onChange}
										hasError={!!errors.type}
										value={value}
										ref_={ref}
									/>
								)}
							/>

							<Controller
								control={control}
								name="description"
								rules={{ validate: validators.validateString, required: true }}
								render={({ field: { onChange, value, ref } }) => (
									<Input
										placeholder={"Description"}
										isTextarea
										onChange={onChange}
										hasError={!!errors.description}
										value={value}
										ref_={ref}
									/>
								)}
							/>

							<Controller
								control={control}
								name="pictures"
								rules={{ validate: validators.validateString, required: true }}
								render={({ field: { onChange, value, ref } }) => (
									<Input
										placeholder={"Dropbox/Google/Apple e.t.c Link for Pictures"}
										onChange={onChange}
										hasError={!!errors.pictures}
										value={value}
										ref_={ref}
									/>
								)}
							/>

							<Button
								loading={loading}
								type="submit"
								text={"SUBMIT PROPERTY"}
								classNames={"py-[13.57px] w-full lg:!py-[23.07px]"}
								textClassNames={"lg:!text-[20px]"}
							/>
						</div>
					</form>
				</div>
			</div>
		</NavigationWrapper>
	);
};

export default PropertyForm;
