import React, { useState } from "react";
import { Button, Input, NavigationWrapper, PhoneNumberInput } from "modules/common/components";
import { Controller, useForm } from "react-hook-form";
import { validators } from "modules/common/utils/functions";
import { useWindowSize, BREAKPOINTS } from "modules/common/hooks";
// import contactImage from "modules/home/assets/images/sell-your-house-diamonds.svg";
import API from "api";
import Jack from "../../common/assets/images/jack.jpg";

const ContactPage = () => {
	const { width } = useWindowSize();
	const [loading, setLoading] = useState(false);
	const {
		handleSubmit,
		control,
		formState: { errors },
		reset,
	} = useForm({
		defaultValues: {
			firstName: "",
			lastName: "",
			email: "",
			phoneNumber: "",
			message: "",
		},
	});

	const onSubmit = async (data) => {
		setLoading(true);
		try {
			// Replace with your API call
			await API.sendContactMessage(data);
			console.log(data);
			reset();
			setLoading(false);
		} catch (e) {
			setLoading(false);
		}
	};

	return (
		<NavigationWrapper containerClassName=" " title={"Contact Us"}>
			<div className="bg-_blue lg:bg-white h-[120px] lg:hidden"></div>
			<div className="pt-[80.37px] lg:pt-[259px]">
				<div className="bg-white px-5 pt-[0px] md:pt-[0px] lg:flex lg:justify-between lg:pl-[171px] lg:pr-0 1400:max-w-[1400px] 1400:mx-auto">
					<div className="flex flex-col justify-between items-center gap-5 md:gap-20 md:items-start md:flex-row relative">
						<div>
							<h3 className="font-black text-[33.38px] text-_blue leading-[41.3px] text-center lg:text-[56px] lg:font-bold lg:leading-[61.6px] lg:text-left">
								Get in Touch
							</h3>
							<p className="mt-[5px] lg:mt-1 text-[27.28px] lg:text-[48px] leading-[30.01px] lg:leading-[52.8px] text-_blue font-bold text-center lg:text-left">
								We're Here to Help
							</p>

							{/* Contact Details Section */}
							<div className="mt-8 lg:mt-12 text-center lg:text-left">
								<p className="text-_blue text-[20px] lg:text-[24px] font-semibold">Contact Details:</p>

								<p className="text-[16px] lg:text-[18px] text-gray-600 mt-2">
									Phone:{" "}
									<a href="tel:+1 (317) 341-2474" className="text-_blue underline">
										+1 (317) 341-2474
									</a>
								</p>

								<p className="text-[16px] lg:text-[18px] text-gray-600 mt-1">
									Email:{" "}
									<a href="mailto:JackedHomes@gmail.com" className="text-_blue underline">
										JackedHomes@gmail.com
									</a>
								</p>

								<p className="text-[16px] lg:text-[18px] text-gray-600 mt-1">
									X:{" "}
									<a href="https://twitter.com/realestate_pawn" className="text-_blue underline">
										@realestate_pawn
									</a>
								</p>

								<p className="text-[16px] lg:text-[18px] text-gray-600 mt-1">
									Address: 123 Example Street, City, Country
								</p>

								<div className="mt-8 flex flex-col items-center md:items-start">
									<p className="text-_blue text-[20px] lg:text-[24px] font-semibold">
										Your Pro for Turning Tough Sales into Fast Wins
									</p>

									<img src={Jack} alt="Jack T. Crenshaw" className="w-[100px] h-[100px]  mt-4" />
									<p className="text-[16px] lg:text-[18px] text-gray-600 mt-2 font-semibold">
										Jack T. Crenshaw
									</p>
									<p className="text-[16px] lg:text-[18px] text-gray-600">Realtor</p>
									<p className="text-[16px] lg:text-[18px] text-gray-600">License #RB25000155</p>
									<p className="text-[16px] lg:text-[18px] text-gray-600">Key Realty</p>
									<a href="tel:+1 (317) 341-2474" className="text-_blue underline">
										+1 (317) 341-2474
									</a>
									<br />
									<a href="mailto:JackedHomes@gmail.com" className="text-_blue underline">
										JackedHomes@gmail.com
									</a>
									<p className="text-[16px] lg:text-[18px] text-gray-600"></p>
								</div>
							</div>
						</div>

						<form
							onSubmit={handleSubmit(onSubmit)}
							className=" mx-auto bg-_blue rounded-[11.89px] lg:rounded-[20.22px] max-w-[310px] lg:max-w-[527px] px-[17px] lg:px-[30px] pt-[36.25px] lg:pt-[60px] pb-[17.03px] lg:pb-[30.46px] mb-[197.08px]"
						>
							<div className="flex flex-col space-y-[13.08px] lg:space-y-[22.24px]">
								<Controller
									control={control}
									name="firstName"
									rules={{ validate: validators.validateString, required: true }}
									render={({ field: { onChange, value, ref } }) => (
										<Input
											placeholder={"First Name"}
											onChange={onChange}
											hasError={!!errors.firstName}
											value={value}
											ref_={ref}
										/>
									)}
								/>

								<Controller
									control={control}
									name="lastName"
									rules={{ validate: validators.validateString, required: true }}
									render={({ field: { onChange, value, ref } }) => (
										<Input
											placeholder={"Last Name"}
											onChange={onChange}
											hasError={!!errors.lastName}
											value={value}
											ref_={ref}
										/>
									)}
								/>

								<Controller
									control={control}
									name="email"
									rules={{ validate: validators.validateEmail, required: true }}
									render={({ field: { onChange, value, ref } }) => (
										<Input
											placeholder={"Email"}
											type="email"
											onChange={onChange}
											hasError={!!errors.email}
											value={value}
											ref_={ref}
										/>
									)}
								/>

								<Controller
									control={control}
									name="phoneNumber"
									rules={{ validate: validators.validatePhoneNumber, required: true }}
									render={({ field: { onChange, value, ref } }) => (
										<PhoneNumberInput
											ref_={ref}
											value={value}
											onChange={onChange}
											hasError={!!errors.phoneNumber}
										/>
									)}
								/>

								<Controller
									control={control}
									name="message"
									rules={{ validate: validators.validateString, required: true }}
									render={({ field: { onChange, value, ref } }) => (
										<Input
											placeholder={"Message"}
											ref_={ref}
											value={value}
											onChange={onChange}
											hasError={!!errors.message}
										/>
									)}
								/>

								<Button
									loading={loading}
									type="submit"
									text={"SEND MESSAGE"}
									classNames={"py-[13.57px] w-full lg:!py-[23.07px]"}
									textClassNames={"lg:!text-[20px]"}
								/>
							</div>
							<div className="flex items-start gap-2 mt-4">
								<input
									type="checkbox"
									id="consentCheckbox"
									name="consentCheckbox"
									className="mt-[6px]"
								/>
								<p className="text-[10px] lg:text-[11px] leading-[14.12px] lg:leading-6 text-white">
									By clicking 'Send Message', I consent to being contacted regarding my inquiry. I
									confirm that I have read and accepted the
									<a className="underline" href="/privacy-policy">
										{" "}
										Privacy Policy{" "}
									</a>
									and
									<a className="underline" href="/terms-of-service">
										{" "}
										Terms & Conditions
									</a>
									.
								</p>
							</div>
						</form>
					</div>
					{/* {width >= BREAKPOINTS.lg && (
						<img
							src={contactImage}
							alt="Contact us"
							className="h-[812.92px] object-contain translate-x-[40.07px]"
						/>
					)} */}
				</div>
			</div>
		</NavigationWrapper>
	);
};

export default ContactPage;
